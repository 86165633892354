interface FavoritesData {
    [key: string]: string[]
}

export class FavoritesHandler {
    private readonly localStorageKey = 'udata';
    private readonly pageLinkSelector = 'a.js-favpage';
    private readonly toggleSelector = '.js-favtoggle';
    private readonly toggleDataAttribute = 'entityid';
    private readonly activeFavClass = 'is-favorite';
    private readonly pageUrlParam = 'tx_p2solr_inriver-entities[entityIds]';
    private readonly clearAllSelector = 'a.js-favclear';
    private readonly listBlockSelector = '.fav-list';

    protected userData: FavoritesData;
    protected favPageLinks: HTMLElement[];

    constructor () {
        this.userData = { favs: [] };
        this.favPageLinks = [];
        this.init();
    }

    init () : void {
        // load from localStorage
        const userData = localStorage.getItem(this.localStorageKey);
        if (userData !== null) {
            this.userData = JSON.parse(userData) as FavoritesData;
            if (!Array.isArray(this.userData.favs)) {
                this.userData.favs = [];
            }
        }

        this.handleProductFavLinks();

        // set number of favorites on fav page link icon(s)
        this.favPageLinks = Array.from(document.querySelectorAll(this.pageLinkSelector));
        // only once, add event handler
        if (this.favPageLinks.length) {
            this.favPageLinks.forEach((elem) => {
                elem.addEventListener('click', (event) => {
                    event.preventDefault();
                    if (event.currentTarget) {
                        const elem = <HTMLAnchorElement>event.currentTarget;
                        window.location.href = `${elem.href}?${this.pageUrlParam}=` + this.userData.favs.join(',');
                    }
                });
            });
        }
        this.setTotalOnFavPageLinks();

        const clearAllBtn = document.querySelector(this.clearAllSelector);
        if (clearAllBtn) {
            clearAllBtn.addEventListener('click', (event) => {
                event.preventDefault();
                if (event.currentTarget) {
                    this.userData.favs = [];
                    this.updateLocalStorage();
                    this.setTotalOnFavPageLinks();
                    const favList = document.querySelector(this.listBlockSelector);
                    if (favList) {
                        favList.innerHTML = '';
                    }
                }
            });
        }
    }

    handleProductFavLinks (): void {
        // toggle fav icons of products shown on the current page
        const productItems = document.querySelectorAll(this.toggleSelector);
        if (productItems.length) {
            productItems.forEach((elem) => {
                // only if user has favs
                if (this.userData.favs.length) {
                    // handle current state
                    const entityId = elem.getAttribute(`data-${this.toggleDataAttribute}`);
                    if (entityId && this.userData.favs.includes(entityId)) {
                        elem.classList.toggle(this.activeFavClass);
                    }
                }
                // add listener for clicks
                elem.addEventListener('click', (event) => {
                    event.preventDefault();
                    if (event.currentTarget) {
                        this.toggleFavorite(<HTMLElement>event.currentTarget);
                    }
                });
            });
        }
    }

    setTotalOnFavPageLinks (): void {
        if (this.favPageLinks.length) {
            this.favPageLinks.forEach((elem) => {
                const badgeElem = elem.querySelector('.badge');
                if (badgeElem) {
                    badgeElem.innerHTML = `${this.userData.favs.length}`;
                }
            });
        }
    }

    toggleFavorite (elem: HTMLElement): void {
        if (!this.userData.favs) {
            return;
        }
        const entityId = elem.getAttribute(`data-${this.toggleDataAttribute}`);
        if (!entityId) {
            return;
        }
        const elemIndex = this.userData.favs.indexOf(entityId);
        if (elemIndex === -1) {
            // add fav
            this.userData.favs.push(entityId);
        } else {
            // remove fav
            this.userData.favs.splice(elemIndex, 1);
        }
        elem.classList.toggle(this.activeFavClass);
        this.setTotalOnFavPageLinks();
        this.updateLocalStorage();
    }

    updateLocalStorage (): void {
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.userData));
    }
}
