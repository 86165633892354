// Version Manta with favorites support but no lightbox for results
// see kuoni.ch for similar solution
//
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Select } from './mdb/mdb-select';

type MdbSelectEvent = Event & {value: string};

export class SearchController {
    protected ajaxType: number = 7383;
    protected selector: string = 'a.solr-ajaxified';

    protected lang: string = 'de';
    protected labels: Labels = {
        de: {
            mdbOptionsSelectedLabel: ' ausgewählt',
            mdbSearchPlaceholder: 'Suchen...'
        },
        fr: {
            mdbOptionsSelectedLabel: ' sélectionnés',
            mdbSearchPlaceholder: 'Rechercher...'
        }
    };

    constructor (language: string, ajaxType?:number, selector?:string) {
        this.lang = language;
        if (ajaxType) {
            this.ajaxType = ajaxType;
        }
        if (selector) {
            this.selector = selector;
        }
        this.init();
    }

    protected scrollTo (element: Element, to: number, duration: number):void {
        if (duration <= 0) return;
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;

        setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            this.scrollTo(element, to, duration - 10);
        }, 10);
    }

    protected getOffsetTop (element: HTMLElement): number {
        return element ? (element.offsetTop + this.getOffsetTop(<HTMLElement>element.offsetParent)) : 0;
    }

    protected getAjaxUri (uri: string): URL {
        const ajaxUri = new URL(uri, window.location.protocol + '//' + window.location.host);
        ajaxUri.searchParams.append('type', `${this.ajaxType}`);
        return ajaxUri;
    }

    protected initMdbSelects (): void {
        const selectFields = document.getElementsByClassName('mdb-select-filter');
        for (const item of selectFields) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const selectField = new Select(item, { optionsSelectedLabel: this.labels[this.lang].mdbOptionsSelectedLabel, searchPlaceholder: this.labels[this.lang].mdbSearchPlaceholder });
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            item.addEventListener('optionSelect.mdb.select', function (element) {
                const myElem = <MdbSelectEvent>element;
                window.searchController.handleSelectOptionSelection(<HTMLSelectElement>element.target, myElem.value);
            });
        }

        const selectMultiFields = document.getElementsByClassName('mdb-multiselect-filter');
        for (const item of selectMultiFields) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const selectField = new Select(item, { displayedLabels: 2, optionsSelectedLabel: this.labels[this.lang].mdbOptionsSelectedLabel, searchPlaceholder: this.labels[this.lang].mdbSearchPlaceholder });
            item.addEventListener('optionSelect.mdb.select', function (element) {
                const myElem = <MdbSelectEvent>element;
                window.searchController.handleSelectOptionSelection(<HTMLSelectElement>element.target, myElem.value);
            });
            item.addEventListener('optionDeselect.mdb.select', function (element) {
                const myElem = <MdbSelectEvent>element;
                window.searchController.handleSelectOptionSelection(<HTMLSelectElement>element.target, myElem.value);
            });
        }
    }

    protected initFavorites (): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-call
        window.favoritesHandler.handleProductFavLinks();
    }

    protected fetchData (uri: string, solrContainer: HTMLElement): boolean {
        // show loading animation
        const solrLoading = <HTMLElement>solrContainer.querySelector('.solr-loading');
        if (solrLoading) {
            solrLoading.style.display = 'block';
        }
        // get parent element
        const solrParent = <HTMLElement>solrContainer.parentElement;

        fetch(uri)
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                if (data) {
                    // replace solr plugin content
                    solrContainer.outerHTML = data;

                    this.initMdbSelects();
                    this.initFavorites();

                    // scroll to top
                    if (solrParent) {
                        this.scrollTo(document.documentElement, this.getOffsetTop(solrParent), 600);
                    }
                }
                return true;
            })
            .catch((err) => {
                console.warn(err);
            });
        return true;
    }

    handleSelectOptionSelection (elem: HTMLSelectElement, value: string): void {
        // close select dropdown
        const selectField = <Select>Select.getInstance(elem);
        selectField.close();

        const solrContainer = <HTMLElement>elem.closest('.tx_solr');
        if (!solrContainer) {
            return;
        }
        // filter usage tracking
        if (typeof window.dataLayer !== 'undefined' && elem.id) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            window.dataLayer.push({ event: 'custom_event', custom_event_data: { event_type: 'filter_results', filter_type: elem.id } });
        }
        const ajaxUri = this.getAjaxUri(value);
        this.fetchData(ajaxUri.toString(), solrContainer);
    }

    init ():void {
        this.initMdbSelects();

        const body = document.querySelector('body');
        if (!body) {
            return;
        }
        body.addEventListener('click', (e) => {
            let target = <HTMLElement>e.target;
            if (!target || !target.closest(this.selector)) {
                return;
            }
            e.preventDefault();
            if (target.tagName !== 'A') {
                target = <HTMLElement>target.closest(this.selector);
            }

            const solrContainer = <HTMLElement>target.closest('.tx_solr');
            if (!solrContainer) {
                return;
            }

            const uri = target.getAttribute('href');
            if (!uri) {
                return;
            }

            const ajaxUri = this.getAjaxUri(uri);
            this.fetchData(ajaxUri.toString(), solrContainer);
        });
    }
}
